import { useMediaQuery } from 'react-responsive';
import { useWindowSize } from 'react-use';

import useIsBrowser from 'hooks/useIsBrowser';
import isNewConsumerApp from 'commons/isNewConsumerApp';

const useScreen = () => {
  const isBrowser = useIsBrowser();

  const returnDefaultsInNewConsumerApp = isNewConsumerApp && !isBrowser;

  const isMobile = useMediaQuery({
    query: '(max-width: 767px)',
  });

  const isMobileOrTablet = useMediaQuery({
    query: '(max-width: 1023px)',
  });

  const isTabletOrDesktop = useMediaQuery({
    query: '(min-width: 768px)',
  });

  const isDesktop = useMediaQuery({
    query: '(min-width: 1024px)',
  });

  const isUltraWide = useMediaQuery({
    query: '(min-width: 1440px)',
  });

  const isExtraWide = useMediaQuery({
    query: '(min-width: 1800px)',
  });

  const { width: windowWidth } = useWindowSize();

  if (returnDefaultsInNewConsumerApp) {
    // In new consumer app, return defaults until the component mounts in the
    // browser to prevent SSR hydration errors. The most common devices used to
    // access fiore.buzz are smartphones so by default we assume the user is
    // on a mobile device with a width of 390px.
    return {
      isMobile: true,
      isMobileOrTablet: true,
      isTabletOrDesktop: false,
      isDesktop: false,
      isUltraWide: false,
      isExtraWide: false,
      windowWidth: 390,
    };
  }

  return {
    isMobile,
    isMobileOrTablet,
    isTabletOrDesktop,
    isDesktop,
    isUltraWide,
    isExtraWide,
    windowWidth,
  };
};

export default useScreen;
