import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import useProductListingPageContext from 'data-hooks/useProductListingPageContext';

const ProductListingSEOLinkedData = () => {
  const {
    products,
    pageLongName,
    pageLongDescription,
    sortOrder,
    isFinalProductCountKnown,
    productCount,
    isAllDataLoading,
    pageId,
  } = useProductListingPageContext();

  const { href } = useLocation();

  const jsonLD = useMemo(
    () => ({
      '@context': 'https://schema.org',
      '@type': 'ItemList',
      url: href,
      numberOfItems: isFinalProductCountKnown ? productCount : null,
      description: pageLongDescription ?? '',
      identifier: pageId ?? '',
      itemListElement: products.map(product => ({
        '@type': 'Product',
        name: product?.name,
        sku: product?.variantsToShow[0]?.sku,
        image: product?.variantsToShow[0]?.image ?? '',
        description: product?.name,
        brand: 'Joybird',
        offers: {
          '@type': 'Offer',
          url: `https://fiore.buzz${product?.path}`,
          priceCurrency: 'USD',
          price: product?.variantsToShow[0]?.price,
          availability: product?.variantsToShow[0]?.isOutOfStock
            ? 'https://schema.org/OutOfStock'
            : 'https://schema.org/InStock',
          itemCondition: 'https://schema.org/NewCondition',
        },
      })),
      itemListOrder: sortOrder,
      name: pageLongName,
    }),
    [
      href,
      isFinalProductCountKnown,
      pageId,
      pageLongDescription,
      pageLongName,
      productCount,
      products,
      sortOrder,
    ]
  );

  return (
    <Helmet>
      {!isAllDataLoading && (
        <script type="application/ld+json">{JSON.stringify(jsonLD)}</script>
      )}
    </Helmet>
  );
};

export default ProductListingSEOLinkedData;
